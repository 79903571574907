import React, { useState, useEffect } from "react";
import { FaPlay, FaGithub, FaTimes } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

/** A tiny custom hook to detect screen width */
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

function ProjectPopup({ project, closeModal }) {
  // Lightbox for viewing a single image in full screen (desktop only)
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const openLightbox = (img) => {
    setSelectedImage(img);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"
      onClick={handleOutsideClick}
    >
      {/* Main Modal Container */}
      <div
        className="relative max-w-4xl w-full m-4 bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-y-auto max-h-[90vh]"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button for the Main Modal */}
        <button
          onClick={closeModal}
          className="absolute top-3 right-3 p-2 text-black dark:text-white bg-gray-200 dark:bg-gray-700 rounded-full hover:bg-gray-300 dark:hover:bg-gray-600 transition"
        >
          <FaTimes />
        </button>

        {/* Conditionally Render: Mobile => Carousel, Desktop => Two-Column */}
        {isMobile ? (
          /* ----------- MOBILE VIEW (CAROUSEL) ----------- */
          <div className="p-4">
            {/* Title & Buttons at top */}
            <div className="mb-4">
              <h3 className="text-2xl font-bold mb-3 text-black dark:text-white">
                {project.title}
              </h3>
              <div className="flex flex-wrap items-center gap-3 mb-6">
                {project.demo && (
                  <a
                    href={project.demo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-black dark:bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-800 dark:hover:bg-gray-600 transition"
                  >
                    <FaPlay className="mr-2" size={18} /> Demo
                  </a>
                )}
                {project.link && (
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-black dark:bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-800 dark:hover:bg-gray-600 transition"
                  >
                    <FaGithub className="mr-2" size={20} /> GitHub
                  </a>
                )}
              </div>
            </div>

            {/* Carousel of Images */}
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={10}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
              loop={true}
              className="mb-4"
            >
              {project.images.map((imgSrc, idx) => (
                <SwiperSlide key={idx}>
                  <img
                    src={imgSrc}
                    alt={`Project ${project.title} - ${idx}`}
                    className="w-full h-auto object-cover rounded-lg select-none"
                    style={{ userSelect: "none" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* Description & Features */}
            <p className="mb-4 text-base md:text-lg leading-relaxed text-black dark:text-gray-100">
              {project.description}
            </p>
            {project.features && (
              <>
                <h4 className="text-lg font-semibold mb-2 text-black dark:text-white">
                  Key Features:
                </h4>
                <ul className="list-none space-y-3 text-base md:text-lg leading-relaxed text-black dark:text-gray-100">
                  {project.features.map((feature, index) => (
                    <li key={index}>
                      <strong className="mr-1">{feature.name}:</strong>
                      <span>{feature.details}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        ) : (
          /* ----------- DESKTOP VIEW (TWO-COLUMN) ----------- */
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full p-4">
            {/* Left Column: Scrollable List of Images */}
            <div className="overflow-y-auto max-h-[70vh] space-y-3">
              {project.images.map((imgSrc, idx) => (
                <img
                  key={idx}
                  src={imgSrc}
                  alt={`Project ${project.title} - ${idx}`}
                  className="w-full h-auto object-cover rounded-lg select-none cursor-pointer"
                  style={{ userSelect: "none" }}
                  onClick={() => openLightbox(imgSrc)}
                />
              ))}
            </div>

            {/* Right Column: Info & Buttons */}
            <div className="flex flex-col max-h-[70vh] overflow-y-auto">
              {/* Title */}
              <h3 className="text-2xl font-bold mb-3 text-black dark:text-white">
                {project.title}
              </h3>

              {/* Description */}
              <p className="mb-4 text-base md:text-lg leading-relaxed text-black dark:text-gray-100">
                {project.description}
              </p>
              {/* Buttons at the Top */}
              <div className="flex flex-wrap items-center gap-3 mb-6">
                {project.demo && (
                  <a
                    href={project.demo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-black dark:bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-800 dark:hover:bg-gray-600 transition"
                  >
                    <FaPlay className="mr-2" size={18} /> Demo
                  </a>
                )}
                {project.link && (
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-black dark:bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-800 dark:hover:bg-gray-600 transition"
                  >
                    <FaGithub className="mr-2" size={20} /> GitHub
                  </a>
                )}
              </div>
              {/* Features (if any) */}
              {project.features && (
                <>
                  <h4 className="text-lg font-semibold mb-2 text-black dark:text-white">
                    Key Features:
                  </h4>
                  <ul className="list-none space-y-3 text-base md:text-lg leading-relaxed text-black dark:text-gray-100">
                    {project.features.map((feature, index) => (
                      <li key={index}>
                        <strong className="mr-1">{feature.name}:</strong>
                        <span>{feature.details}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Lightbox Modal (only in Desktop mode) */}
      {lightboxOpen && selectedImage && !isMobile && (
        <div
          className="fixed inset-0 bg-black bg-opacity-70 z-50 flex justify-center items-center"
          onClick={closeLightbox}
        >
          <div className="relative p-4" onClick={(e) => e.stopPropagation()}>
            {/* Close Button for Lightbox */}
            <button
              onClick={closeLightbox}
              className="absolute top-3 right-3 p-2 text-white bg-gray-600 rounded-full hover:bg-gray-500 transition"
            >
              <FaTimes />
            </button>
            <img
              src={selectedImage}
              alt="Lightbox"
              className="max-w-full max-h-[90vh] object-contain rounded-lg select-none"
              style={{ userSelect: "none" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectPopup;
