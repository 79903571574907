import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion"; // Removed AnimatePresence as it's unused
import { useInView } from "react-intersection-observer"; // Import Intersection Observer hook
import { FaDownload } from "react-icons/fa6";

const education = [
  {
    institution: "Grand Canyon University",
    degree: "Bachelor's Degree in Business Management",
    gpa: "3.5 GPA",
    year: "August 2021 - December 2024",
    details:
      "Participated in Intramural Sports and Local Outreach. Graduating a semester early.",
  },
  {
    institution: "Sioux Falls Christian",
    degree: "High School Diploma",
    gpa: "3.8 GPA",
    year: "August 2017 - December 2020",
    details:
      "Participated in Debate, Football, Track, Mentorship, and Community Outreach. Graduated with High Honors Roll.",
  },
];

const certifications = [
  {
    title: "Full Stack Software Engineering Certification",
    issuer: "Codecademy",
    dateRange: "November 2022 - June 2023",
  },
  {
    title: "Certified ScrumMaster® (CSM®)",
    issuer: "Scrum Alliance",
    date: "June 2023",
    validUntil: "June 2025",
    credentialID: "1794821",
  },
];

const volunteer = [
  {
    role: "City Hope",
    duration: "March 2023 - ...",
    details:
      "Shopped with locals at a food bank sponsered by Christ Church that is set up as a grocery store so we can connect with individuals as they walk through.",
  },
  {
    role: "Audio Visual Technician",
    duration: "July 2017 - 2022",
    details:
      "Managed sound and visual systems during Sunday worship services at Abiding Savior Lutheran Church.",
  },
  {
    role: "Rotary International",
    details:
      "Participated in events that provided a safe and inclusive space for children with special needs.",
  },
  {
    role: "The Banquet",
    details:
      "Served and prepared meals for homeless and low-income families, and provided a listening ear.",
  },
  {
    role: "Operation Christmas Child",
    details:
      "Participated in a trip to a distribution center, where our group sorted and packed gifts for Operation Christmas Child.",
  },
  {
    role: "3D Printer Operator for COVID-19 Relief",
    details:
      "Utilized 3D printing skills to produce medical supplies to be donated, contributing to pandemic relief efforts.",
  },
];

const professionalExperience = [
  {
    role: "Technical Support (Tier 2) | Various Other Roles ",
    company: "SKED Software",
    period: "April 2024 - Present",
    tasks: [
      "Assisted in launching and maintaining a new software product.",
      "Designed and developed internal applications using JavaScript frameworks to streamline company operations, enhancing productivity and saving hundreds of hours.",
      "Promoted to Tier 2 Support swiftly by demonstrating advanced technical skills and a strong development background.",
      "Collaborated with the development team to identify, debug, and resolve software issues, thereby improving overall product quality and user satisfaction.",
      "Executed complex database queries and identified data discrepancies to enhance data integrity and optimize application performance.",
      "Developed and tested APIs with Postman to ensure the reliability and efficiency of office server integrations.",
      "Managed and resolved intricate technical support tickets, providing solutions that supported both office operations and customer needs.",
    ],
  },
  {
    role: "AI Workshop Instructor",
    company: "Independent",
    period: "November 2023 - January 2024",
    tasks: [
      "Collaborated with Primerica’s regional president to design and deliver a comprehensive AI curriculum for over 150 financial professionals, simplifying complex concepts through practical software applications.",
      "Led individual and group hands-on training sessions to enhance participants’ proficiency with AI tools.",
      "Provided personalized mentoring to ensure learning needs were met and fostered a deep understanding of AI integration with financial services.",
      "Created AI Agents to automate repetitive tasks, increasing performance and reducing manual errors.",
    ],
  },
  {
    role: "Business Operations Intern",
    company: "Highest Health Chiropractic",
    period: "April 2023 - November 2023",
    tasks: [
      "Coordinated the company's relocation by managing tech setup.",
      "Head of project for the transition to digital forms, significantly reducing paperwork.",
      "Managed data entry tasks with precision, ensuring accuracy across all systems.",
      "Delivered exceptional customer service, addressing concerns and enhancing satisfaction.",
    ],
  },
];

function Resume() {
  // Animation controls for different sections
  const headingControls = useAnimation();
  const [headingRef, headingInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const workControls = useAnimation();
  const [workRef, workInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const volunteerControls = useAnimation();
  const [volunteerRef, volunteerInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const certificationsControls = useAnimation();
  const [certificationsRef, certificationsInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const educationControls = useAnimation();
  const [educationRef, educationInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // Trigger animations when elements come into view
  useEffect(() => {
    if (headingInView) {
      headingControls.start("visible");
    }
    if (workInView) {
      workControls.start("visible");
    }
    if (volunteerInView) {
      volunteerControls.start("visible");
    }
    if (certificationsInView) {
      certificationsControls.start("visible");
    }
    if (educationInView) {
      educationControls.start("visible");
    }
  }, [
    headingInView,
    headingControls,
    workInView,
    workControls,
    volunteerInView,
    volunteerControls,
    certificationsInView,
    certificationsControls,
    educationInView,
    educationControls,
  ]);

  // Define animation variants for different sections
  const headingVariants = {
    hidden: { opacity: 0, y: -30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.1, duration: 0.5 },
    }),
  };

  return (
    <div className="container mt-10 mx-auto sm:p-4 max-w-6xl">
      {/* Animated Resume Heading with Download Button */}
      <motion.div
        ref={headingRef}
        variants={headingVariants}
        initial="hidden"
        animate={headingControls}
        className="flex flex-row justify-center sm:justify-start items-center mb-8"
      >
        <h2 className="text-4xl md:text-6xl pl-4 font-bold text-black dark:text-white text-center sm:text-left">
          Resume
        </h2>
        {/* <a href="./Daniel Shemon - Resume .pdf" download>
          <button className="ml-5 w-8 h-8 rounded-full transition duration-300 bg-black dark:bg-white text-white dark:text-black">
            <FaDownload className="inline" />
          </button>
        </a> */}
      </motion.div>

      {/* Grid Container */}
      <div className="grid grid-cols-1 bg-white dark:bg-gray-950 p-4 m-4 ZZ border-black gap-0.5 rounded-lg md:grid-cols-3 lg:gap-4 text-black dark:text-white">
        {/* Work Experience Section - takes up two columns on medium screens */}
        <motion.div
          ref={workRef}
          variants={sectionVariants}
          initial="hidden"
          animate={workControls}
          className="md:col-span-2 border-b md:border-b-0 border-black pb-4 md:pb-0"
        >
          <h2 className="text-xl font-bold text-left m-4">Work Experience</h2>
          {professionalExperience.map((exp, index) => (
            <motion.div
              key={index}
              className="m-6 p-4 rounded-lg bg-white dark:bg-gray-950 border border-black dark:border-white hover:shadow-lg transition duration-500"
              custom={index}
              variants={cardVariants}
              initial="hidden"
              animate="visible"
            >
              <h3 className="font-bold text-lg">
                {exp.role} - {exp.company}
              </h3>
              <p className="italic">{exp.period}</p>
              <ul className="list-disc pl-4 space-y-2">
                {exp.tasks.map((task, taskIndex) => (
                  <li key={taskIndex} className=" max-w-3xl">
                    {task}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </motion.div>

        {/* Volunteer Section - takes up one column on medium screens */}
        <motion.div
          ref={volunteerRef}
          variants={sectionVariants}
          initial="hidden"
          animate={volunteerControls}
          className="md:col-span-1 border-b md:border-b-0 border-black pb-4 md:pb-0"
        >
          <h2 className="text-xl font-bold text-left m-4">Volunteer Work</h2>
          {volunteer.map((vol, index) => (
            <motion.div
              key={index}
              className="m-6 p-4 rounded-lg bg-white dark:bg-gray-950 border border-black dark:border-white hover:shadow-lg transition duration-500"
              custom={index}
              variants={cardVariants}
              initial="hidden"
              animate="visible"
            >
              <h3 className="font-bold text-lg mb-2">{vol.role}</h3>
              <p>{vol.details}</p>
            </motion.div>
          ))}
        </motion.div>

        {/* Certifications Section - takes up one column */}
        <motion.div
          ref={certificationsRef}
          variants={sectionVariants}
          initial="hidden"
          animate={certificationsControls}
          className="md:col-span-1 border-b md:border-b-0 border-black pb-4 md:pb-0"
        >
          <h2 className="text-xl font-bold text-left m-4">Certifications</h2>
          {certifications.map((cert, index) => (
            <motion.div
              key={index}
              className="m-6 p-4 rounded-lg bg-white dark:bg-gray-950 border border-black dark:border-white hover:shadow-lg transition duration-500"
              custom={index}
              variants={cardVariants}
              initial="hidden"
              animate="visible"
            >
              <h3 className="font-bold text-lg mb-2">{cert.title}</h3>
              <p>
                {cert.issuer} - {cert.date}
              </p>
            </motion.div>
          ))}
        </motion.div>

        {/* Education Section - takes up two columns on medium screens */}
        <motion.div
          ref={educationRef}
          variants={sectionVariants}
          initial="hidden"
          animate={educationControls}
          className="md:col-span-2"
        >
          <h2 className="text-xl font-bold text-left m-4">Education</h2>
          {education.map((edu, index) => (
            <motion.div
              key={index}
              className="m-6 p-4 rounded-lg bg-white dark:bg-gray-950 border border-black dark:border-white hover:shadow-lg transition duration-500"
              custom={index}
              variants={cardVariants}
              initial="hidden"
              animate="visible"
            >
              <h3 className="font-bold">{edu.institution}</h3>
              <p className="italic">{edu.year}</p>
              <ul className="list-disc list-inside space-y-2">
                <li className="pl-4 max-w-3xl">{edu.degree}</li>
                <li className="pl-4 max-w-3xl">{edu.details}</li>
              </ul>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* References Section - centered below the grid */}
      <div className="flex justify-center text-black dark:text-white">
        <p>References available upon request.</p>
      </div>
    </div>
  );
}

export default Resume;
