import React from "react";

const Navbar = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error(`No section found with ID: ${sectionId}`);
    }
  };

  return (
    <nav className=" bg-gray-200 dark:bg-gray-800  pt-4">
      <ul className="flex justify-around flex-nowrap overflow-x-auto p-1 text-center">
        {["about", "projects", "resume", "skills", "contact"].map((section) => (
          <li
            key={section}
            className="text-gray-900 dark:text-gray-200 hover:text-orange-600 dark:hover:text-orange-400 cursor-pointer px-2 py-1 rounded-md text-sm md:text-lg font-medium whitespace-nowrap"
            onClick={() => scrollToSection(section)}
          >
            {section.charAt(0).toUpperCase() + section.slice(1)}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
