import React, { useState, useEffect } from "react";
import {
  FaArrowUp,
  FaSun,
  FaMoon,
  FaGithub,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { useDarkMode } from "../context.jsx";

const CombinedSidebar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { darkMode, toggleDarkMode } = useDarkMode();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleVisibility = () => {
    setIsVisible(window.scrollY > 25);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    const popupTimer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    const autoDismissTimer = setTimeout(() => {
      setShowPopup(false);
    }, 10000);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
      clearTimeout(popupTimer);
      clearTimeout(autoDismissTimer);
    };
  }, []);

  const iconVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    hover: { scale: 1.2 },
  };

  const popupVariants = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 50 },
    shake: {
      x: [0, -10, 10, -10, 10, 0],
      transition: { duration: 0.5, repeat: Infinity, repeatType: "loop" },
    },
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed z-50 bottom-2 sm:bottom-5 left-2 sm:left-5 h-fit w-12 sm:w-16 bg-white dark:bg-gray-950 rounded-lg shadow-lg flex flex-col items-center py-4 transition-all duration-500"
          initial={{ x: -250 }}
          animate={{ x: 0 }}
          exit={{ x: -250 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
        >
          {/* Scroll to Top Button */}
          <motion.button
            onClick={scrollToTop}
            className="flex items-center justify-center mb-4 text-orange-500 hover:text-orange-600"
            style={{ padding: 0 }}
            variants={iconVariants}
            whileHover="hover"
            aria-label="Scroll to Top"
            title="Scroll to Top"
          >
            <FaArrowUp size={24} />
          </motion.button>

          {/* Dark Mode Toggle */}
          <motion.button
            onClick={toggleDarkMode}
            className={`flex items-center justify-center mb-2 ${
              darkMode
                ? "text-blue-500 hover:text-blue-600"
                : "text-purple-500 hover:text-purple-600"
            }`}
            style={{ padding: 0 }}
            variants={iconVariants}
            whileHover="hover"
            aria-label="Toggle Dark Mode"
            title={darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
          >
            {darkMode ? <FaSun size={24} /> : <FaMoon size={24} />}
          </motion.button>
          <hr className="w-8 my-2 border-gray-300 dark:border-gray-600" />
          {/* Social Media Icons */}
          <motion.a
            href="https://www.github.com/dans2177"
            className="text-black dark:text-white text-2xl md:text-3xl mb-4 hover:text-gray-600 dark:hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
            variants={iconVariants}
            whileHover="hover"
            aria-label="GitHub"
            title="Visit my GitHub"
          >
            <FaGithub />
          </motion.a>
          <motion.a
            href="https://www.linkedin.com/in/daniel-shemon-0931b122a/"
            className="text-black dark:text-white text-2xl md:text-3xl mb-4 hover:text-gray-600 dark:hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
            variants={iconVariants}
            whileHover="hover"
            aria-label="LinkedIn"
            title="Visit my LinkedIn"
          >
            <FaLinkedin />
          </motion.a>
          <motion.a
            href="https://twitter.com/danieldridley"
            className="text-black dark:text-white text-2xl md:text-3xl hover:text-gray-600 dark:hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
            variants={iconVariants}
            whileHover="hover"
            aria-label="Twitter"
            title="Visit my Twitter"
          >
            <FaTwitter />
          </motion.a>

          {/* Popup */}
          {showPopup && (
            <motion.div
              className="absolute bottom-0 left-14 md:left-20 bg-black text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2 whitespace-nowrap"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={popupVariants}
              whileHover="shake"
            >
              Check out my socials!
            </motion.div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CombinedSidebar;
