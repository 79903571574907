// Contact.jsx
import React from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Contact = () => {
  // Intersection Observers for heading & cards
  const [headingRef, headingInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [emailRef, emailInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const [phoneRef, phoneInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // Animation Variants
  const headingVariants = {
    hidden: { opacity: 0, y: -30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, duration: 0.6, ease: "easeOut" },
    }),
  };

  return (
    <div className="container mt-10 mx-auto p-4 max-w-6xl">
      {/* Animated Heading */}
      <motion.h2
        ref={headingRef}
        variants={headingVariants}
        initial="hidden"
        animate={headingInView ? "visible" : "hidden"}
        className="text-4xl md:text-6xl font-bold text-black dark:text-white text-center sm:text-left mb-8"
      >
        Let's Stay Connected
      </motion.h2>

      {/* Contact Cards Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mx-auto">
        {/* Email Card */}
        <motion.div
          ref={emailRef}
          custom={0}
          variants={cardVariants}
          initial="hidden"
          animate={emailInView ? "visible" : "hidden"}
          className="flex flex-col justify-center items-center shadow-lg rounded-lg bg-white   dark:bg-gray-950 transform hover:scale-105 transition duration-300 p-6"
        >
          <FaEnvelope className="text-5xl mb-4 text-black dark:text-white" />
          <a
            href="mailto:danielshemon@outlook.com"
            className="text-black dark:text-white text-lg hover:underline"
          >
            danielshemon@outlook.com
          </a>
        </motion.div>

        {/* Phone Card */}
        <motion.div
          ref={phoneRef}
          custom={1}
          variants={cardVariants}
          initial="hidden"
          animate={phoneInView ? "visible" : "hidden"}
          className="flex flex-col justify-center items-center shadow-lg rounded-lg bg-white  dark:bg-gray-950 transform hover:scale-105 transition duration-300 p-6"
        >
          <FaPhone className="text-5xl mb-4 text-black dark:text-white" />
          <a
            href="tel:6052154285"
            className="text-black dark:text-white text-lg hover:underline"
          >
            (605) 215-4285
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
