// Header.jsx
import React from "react";
import { motion } from "framer-motion";

function Header() {
  // Define animation variants for the container and child elements
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        when: "beforeChildren",
        staggerChildren: 0.3, // Stagger the animation of child elements
      },
    },
  };

  const headingVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  const subHeadingVariants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
  };

  const paragraphVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <header className="text-center px-10 pt-4">
      {/* Animated Container */}
      <motion.div
        className="h-96 md:h-[85vh] bg-white dark:bg-gray-950 shadow-xl  border-orange-500 text-gray-950 dark:text-white rounded-lg flex flex-col justify-between p-6 md:p-10"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Headings Container */}
        <motion.div className="flex-grow">
          <motion.h1
            className="text-xl font-bold text-left md:text-3xl lg:text-4xl mb-4"
            variants={headingVariants}
          >
            Hello World! My name is
          </motion.h1>
          <motion.h1
            className="font-bold text-left font-pixelify text-6xl md:text-8xl lg:text-9xl items-center flex"
            variants={subHeadingVariants}
          >
            <span className="flex-1">
              <span className="whitespace-pre-wrap">Daniel Shemon</span>
              <span className="animate-blink text-orange-600">_</span>
            </span>
          </motion.h1>
        </motion.div>

        {/* Paragraph */}
        <motion.p
          className="text-left text-black dark:text-white mt-8 md:mt-16 md:p-4  md:text-4xl leading-normal sm:leading-relaxed font-normal"
          variants={paragraphVariants}
        >
          Software Developer with a<br />
          Bachelors in Business Management <br />
        </motion.p>
      </motion.div>
    </header>
  );
}

export default Header;
